import useGetKeysFromUrl from 'utils/use-get-keys-from-url';
import { useCallback } from 'react';

export const OWNER_NAME_PARAM = 'ownerFirstName';

export function useGetOwnerFirstName() {
  const ownerFirstName = useGetKeysFromUrl(OWNER_NAME_PARAM);
  return useCallback(() => {
    if (ownerFirstName) {
      return ownerFirstName;
    }
    return null;
  }, [ownerFirstName]);
}
