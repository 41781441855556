import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

// Own
import { useGetUserItem } from '../use-get-user-item';
import { ContactMethod, LoadingContactMethod, Props } from './types';
import { useSendMetricNoUserContactOwner } from '../use-send-metrics';
import { ReactComponent as PhoneSvg } from './phone.svg';
import Avatar from 'components/avatar';
import OwnerMesage from '../owner-message';
import DownloadAppButton from 'components/download-app/button/download-app-button.component';
import OpenAppBanner from './open-app-banner';
import useGetKeysFromUrl from 'utils/use-get-keys-from-url';
import useGetDownloadAppUrl from 'core/use-get-down-load-app-url';
import useUpdateLocation from '../use-update-location';
import useNavigationByContactMethod from './use-navigation-by-contact-method';

const { Paragraph } = Typography;

const NoUserContactOwner: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('tag-found');
  const getUserItem = useGetUserItem();
  const userItem = getUserItem();
  const contactDeepLink = useGetKeysFromUrl('contactDeepLink');
  const goToContactMethod = useNavigationByContactMethod(userItem!);
  const updateLocation = useUpdateLocation(userItem?.scanTrackingUuid as string);

  const [loading, setLoadingByContactMethod] = useState<LoadingContactMethod>({});

  const clickHandlerFor = (contactMethod: ContactMethod) => async () => {
    setLoadingByContactMethod({ [contactMethod]: true });
    await updateLocation();
    setLoadingByContactMethod({ [contactMethod]: false });

    goToContactMethod(contactMethod)();
  };

  useSendMetricNoUserContactOwner();

  const isAndroid = !!contactDeepLink;
  const getDownLoadAppUrl = useGetDownloadAppUrl(isAndroid);

  if (!userItem) return null;

  const { avatarUrl, description, user, status } = userItem;
  return (
    <div className={className}>
      <div className="contact-owner-container">
        <OpenAppBanner contactDeepLink={contactDeepLink!} />
        <Avatar avatarUrl={avatarUrl} />
        <OwnerMesage message={description} firstName={user.firstName} status={status} />

        <div className="contact-owner-buttons-container">
          <Button
            shape="round"
            className="whats-app__button-container"
            onClick={clickHandlerFor(ContactMethod.Whatsapp)}
            loading={loading[ContactMethod.Whatsapp]}
          >
            <img src="/assets/whats-app.svg" alt="Items" />
            <span>{t('whatsAppButton', { firstName: user.firstName })}</span>
          </Button>

          <Button
            shape="round"
            className="call__button-container"
            onClick={clickHandlerFor(ContactMethod.Call)}
            loading={loading[ContactMethod.Call]}
          >
            <PhoneSvg />
            <span>{t('buttonCall', { firstName: user.firstName })}</span>
          </Button>
        </div>
      </div>

      <div className="contact-owner__download-button-container">
        <Paragraph className="join-community__text">{t('joinCommunity')}</Paragraph>
        <DownloadAppButton
          type="link"
          size="small"
          onClick={clickHandlerFor(ContactMethod.Download)}
          downloadAppUrl={getDownLoadAppUrl()}
        >
          {t('downloadButton')}
        </DownloadAppButton>
      </div>
    </div>
  );
};

export default NoUserContactOwner;
