import { WHATS_APP_API } from 'constants/env-variables';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useGetWhatsAppLink() {
  const { t } = useTranslation('tag-found');

  return useCallback(
    ({ phone, firstName }: Options) => {
      return `${WHATS_APP_API}${phone.replaceAll(' ', '')}&text=${encodeURIComponent(
        t('contactMessage.message', { firstName: firstName }) as string,
      )}`;
    },
    [t],
  );
}

export interface Options {
  phone: string;
  firstName: string;
}
