import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Own
import { BASE_FULL_API_V3 } from 'constants/env-variables';

const ContactRedirection: FunctionComponent = () => {
  const urlParams = useParams<{ appLinkUrls: string }>();

  useEffect(() => {
    // eslint-disable-next-line max-len
    const newLocation = `${BASE_FULL_API_V3}/safetags/app-link/contact-redirection/${urlParams.appLinkUrls}`;
    window.location.href = newLocation;
  }, [urlParams.appLinkUrls]);

  return null;
};

export default ContactRedirection;
