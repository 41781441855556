import { FunctionComponent } from 'react';

const HeartImage: FunctionComponent<Props> = ({ className }) => {
  return (
    <img
      className={className}
      src="/assets/scan-safetag/heart-image.png"
      srcSet="/assets/scan-safetag/heart-image.png 343w,
              /assets/scan-safetag/heart-image@2x.png 686w,
              /assets/scan-safetag/heart-image@3x.png 1029w"
      alt="HEART SOSAFE GIFT"
    />
  );
};

interface Props {
  className?: string;
}

export default HeartImage;
