import { FunctionComponent } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingScreen: FunctionComponent = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 46 }} spin />;
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingScreen;
