export default {
  content: {
    title: 'The finder might contact you!',
    description: {
      withLocation:
        'Someone has just scanned your SAFE Tag "{{-safe_tag_name}}" at {{hour}} near {{-location}} and it is possible that they will try to reach you.',
      normal:
        'Someone has just scanned your SAFE Tag "{{-safe_tag_name}}" at {{hour}} and it is possible that they will try to reach you.',
    },
    disclaimer:
      'They might call you, text you or get in touch with you through SOSAFE private messages.',
  },
};
