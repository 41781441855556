// Own
import { SEND_LOCATION_TIMEOUT } from 'constants/env-variables';
import { useCallback, useEffect, useRef } from 'react';
import useGetLocation from './use-get-location';
import useSendLocation from './use-send-location';

export default function useUpdateLocation(scanTrackingUuid: string) {
  const updateLocation = useExecuteUpdate(scanTrackingUuid);
  useAutomaticUpdate(updateLocation);

  return updateLocation;
}

function useExecuteUpdate(scanTrackingUuid: string) {
  const { getLocation } = useGetLocation();
  const { sendLocation } = useSendLocation();

  return useCallback(async () => {
    const location = await getLocation();
    await sendLocation({ scanTrackingUuid, location });
  }, [getLocation, scanTrackingUuid, sendLocation]);
}

function useAutomaticUpdate(updateLocation: () => Promise<void>) {
  const executedRef = useRef(false);

  useEffect(() => {
    if (executedRef.current) {
      return;
    }
    executedRef.current = true;

    setTimeout(async () => {
      await updateLocation();
    }, SEND_LOCATION_TIMEOUT);
  }, [executedRef, updateLocation]);
}
