import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

//Own
import { ShareLocationStatus } from '../type';

const { Paragraph } = Typography;
const OwnerMessage: FunctionComponent<Props> = ({ className, message, firstName, status }) => {
  const { t } = useTranslation('tag-found');
  const locationsStatus = status ?? ShareLocationStatus.Deny;

  return (
    <div className={className}>
      <Paragraph className={'owner-message__title'}>
        {t(`locationStatus.title.${locationsStatus}`, { firstName })}
      </Paragraph>

      {message ? (
        <>
          <Paragraph className={'owner__message-label'}>
            {t('ownerMessageLabel', { firstName })}
          </Paragraph>
          <Paragraph className="owner-message__text">{`"${message}"`}</Paragraph>
        </>
      ) : (
        <Paragraph className="owner-message__no-text">
          {t(`locationStatus.description.${locationsStatus}`, { firstName })}
        </Paragraph>
      )}
    </div>
  );
};

interface Props {
  className?: string;
  message: string | null;
  firstName: string;
  status: ShareLocationStatus;
}

export default OwnerMessage;
