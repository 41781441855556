import { useGetLang } from 'utils/use-get-keys-from-url';

function useGetDownloadAppUrl(isAndroid: boolean) {
  const lang = useGetLang();
  return () => {
    if (isAndroid) {
      return `/${lang}/public/android-download-page`;
    }
    return '';
  };
}

export default useGetDownloadAppUrl;
