import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function useDescription({ address, hour, itemName }: Options) {
  const { t } = useTranslation('scanned-safetag');

  const createMarkup = (encodedHtml: string) => ({
    __html: _.unescape(encodedHtml),
  });

  const description = t(
    address ? 'content.description.withLocation' : 'content.description.normal',
    {
      safe_tag_name: `<span style="font-weight: bold;">${itemName}</span>`,
      hour,
      location: `<span style="font-weight: bold;color: black;">${address}</span>`,
    },
  );

  return createMarkup(description);
}

export interface Options {
  address: string;
  hour: string;
  itemName: string;
}
