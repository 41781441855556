//Own
import { useCallback, useEffect } from 'react';
import { EventTypes } from 'utils/metrics/types';
import { useLogEvent } from 'utils/metrics/use-metrics';

export const useSendMetricNoUserContactOwner = () => {
  const logEvent = useLogEvent();
  useEffect(() => {
    logEvent({
      eventType: EventTypes.WebviewScanQr,
      eventProperties: {
        user_app_sosafe: false,
        tag_related: true,
      },
    });
  }, [logEvent]);
};

export const useSendMetricUserContactOwner = () => {
  const logEvent = useLogEvent();
  useEffect(() => {
    logEvent({
      eventType: EventTypes.WebviewScanQr,
      eventProperties: {
        user_app_sosafe: true,
        tag_related: true,
      },
    });
  }, [logEvent]);
};

export const useSendMetricContactByWhatsApp = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrContactWa,
    });
  }, [logEvent]);
};

export const useSendMetricContactByCall = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrContactCall,
    });
  }, [logEvent]);
};

export const useSendMetricDownloadApp = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrScanDownload,
      eventProperties: {
        source: 'webview Safetag linked without app',
      },
    });
  }, [logEvent]);
};

export const useSendMetricMessageToOwner = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrContactDm,
    });
  }, [logEvent]);
};

export const useSendMetricCreateReport = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrContactReport,
    });
  }, [logEvent]);
};
