import { FunctionComponent } from 'react';

// Own
import { SOSAFE_DYNAMIC_LINK_DOWNLOAD_APP } from 'constants/env-variables';

const DownloadAppButton: FunctionComponent<Props> = (props) => {
  const { children, className, onClick, downloadAppUrl } = props;

  return (
    <a
      onClick={onClick}
      className={className}
      href={downloadAppUrl || SOSAFE_DYNAMIC_LINK_DOWNLOAD_APP}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="/assets/common/sosafe-icon.svg" alt="SOSAFE Message" />
      <span>{children}</span>
    </a>
  );
};

interface Props {
  className?: string;
  onClick?: () => void;
  downloadAppUrl?: string;
  type?: 'primary' | 'link';
  shape?: 'round';
  size?: 'medium' | 'small';
}

export default DownloadAppButton;
