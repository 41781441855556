import useGetKeysFromUrl from 'utils/use-get-keys-from-url';

export default function useParams(): Params {
  const itemName = useGetKeysFromUrl('itemName') || '';
  const hour = useGetKeysFromUrl('hour') || '';
  const address = useGetKeysFromUrl('address') || '';
  const img = useGetKeysFromUrl('img');
  const scanTrackingUuid = useGetKeysFromUrl('scanTrackingUuid');

  return {
    itemName,
    hour,
    img,
    address,
    scanTrackingUuid,
  };
}

export interface Params {
  itemName: string;
  hour: string;
  address: string;
  img: string | null;
  scanTrackingUuid: string | null;
}
export interface Coordinates {
  latitude: number;
  longitude: number;
}
