import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Own
const initialState: State = {
  title: 'my-tittle-test',
};

const mainState = createSlice({
  name: 'mainState',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});
interface State {
  title: string;
}

export default mainState;
