import { useCallback } from 'react';

//Own
import { LogEventParams, SetUserProperties } from './types';
import { getMetricsProvider } from './';

export function useMetricsInitializer() {
  return useCallback(() => {
    getMetricsProvider().initializeMetrics();
  }, []);
}

export const useLogEvent = () => {
  return useCallback((option: LogEventParams) => {
    getMetricsProvider().logEvent(option);
  }, []);
};

export const useSetUserId = () => {
  return useCallback((userId: string | number) => {
    getMetricsProvider().setUserId(userId + '');
  }, []);
};

export const useSetUserProperties = (): SetUserProperties => {
  return useCallback((options) => {
    getMetricsProvider().setUserProperties(options);
  }, []);
};
