const names = [
  'download-aplication',
  'open-app-banner',
  'pre-contact',
  'safetag-final-thank-you',
  'safetag-not-linked-with-app',
  'safetag-not-linked',
  'scanned-safetag',
  'tag-found',
];
export default names;
