import names from './namespaces';

function buildNamespace(lang: string) {
  const result: Record<string, string> = {};

  for (const x of names) {
    result[x] = require(`locales/${lang}/${x}.ts`).default;
  }

  return result;
}

export const languages = ['es', 'en', 'fr', 'pt'];

export const resources = {
  ...languages.reduce((acc, lang) => {
    acc[lang] = buildNamespace(lang);
    return acc;
  }, {} as Record<string, any>),
};
