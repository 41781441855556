import { combineReducers, AnyAction, CombinedState, Middleware } from 'redux';
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

// Own
import mainState from './main';

const appReducers = combineReducers({
  mainState: mainState.reducer,
});

export const rootReducer = (state: CombinedState<any>, action: AnyAction) => {
  return appReducers(state as any, action as any);
};

const extraMiddlewares: Middleware[] = [];

const store = rtkConfigureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(extraMiddlewares),
});

export default store;

// TypeScript helpers.
// See https://redux-toolkit.js.org/usage/usage-with-typescript
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
// Strongly typed useDispatch and useSelector hooks with our store type definitions.
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppMiddlewareGetState = () => RootState;
export type AppReduxMiddleware = (
  dispatch: AppDispatch,
  getState: () => RootState,
) => void | Promise<void>;
