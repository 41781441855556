import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources, languages } from './resources';

const defaultLang = 'es';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['path'],
    },
    resources,
    fallbackLng: 'es',
    debug: !['prod', 'stage'].includes(process.env.REACT_APP_SOSAFE_ENV as string),
    react: {
      bindI18nStore: 'added',
      useSuspense: false,
    },
  });

export const getLang = () => {
  const i18nLanguage = i18n.language;
  let lang = '';

  if (i18nLanguage) {
    lang = i18nLanguage.split('-')[0];
  }

  return languages.includes(lang) ? lang : defaultLang;
};

export type Langs = 'en' | 'es' | 'fr' | 'pt';
export default i18n;
