import { FunctionComponent } from 'react';

//Own
import { ReactComponent as Map } from './map.svg';
import Pin from './pin-svg';

const AvatarComponent: FunctionComponent<Props> = ({ className, avatarUrl }) => {
  return (
    <div className={className}>
      <Pin className="safetag-avatar__container" url={avatarUrl || '/assets/avatar.png'} />
      <Map className="safetag-avatar__image" />
    </div>
  );
};

interface Props {
  className?: string;
  avatarUrl: string | null;
}
export default AvatarComponent;
