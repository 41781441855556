import { useHistory } from 'react-router-dom';

// Own
import { getLang } from 'utils/i18n';

const WAITING_TIME_FOR_REDIRECT_THANKS_PAGE = 500;

export default function useRedirectToThanksPage() {
  const history = useHistory();
  return (userFirstName: string, contactDeepLink: string) => {
    const lang = getLang();
    let isAndroid = '';
    if (!!contactDeepLink) isAndroid = '&isAndroid=1';

    setTimeout(() => {
      history.push(`/${lang}/public/thanks?ownerFirstName=${userFirstName}${isAndroid}`);
    }, WAITING_TIME_FOR_REDIRECT_THANKS_PAGE);
  };
}
