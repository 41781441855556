export default {
  title: 'Você encontrou a SAFE Tag de {{firstName}}!',
  subtitle:
    'Ajude {{firstName}} enviando o local da SAFE Tag. Enviaremos apenas este ponto de posição.',
  buttons: {
    sendLocation: 'Enviar localização e contato',
    dontSend: 'Não envie, mas entre em contato',
  },
  actionUnavailable: 'A ação não pode ser concluída.',
  error: 'Ocorreu um erro. Por favor, tente novamente!',
};
