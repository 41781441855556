import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

// Own
import { ContactMethod } from './types';
import { useGetUserItem } from '../use-get-user-item';
import Avatar from 'components/avatar';
import OwnerMesage from '../owner-message';
import { useSendMetricUserContactOwner } from '../use-send-metrics';
import ContactByOtherMeans from './contact-by-other-means';
import useUpdateLocation from 'core/contact-owner/use-update-location';
import useNavigationByContactMethod from './use-navigation-by-contact-method';

const UserContactOwner: FunctionComponent<Props> = ({ className }) => {
  useSendMetricUserContactOwner();
  const { t } = useTranslation('tag-found');
  const userItem = useGetUserItem()()!;

  const updateLocation = useUpdateLocation(userItem.scanTrackingUuid!);
  const [loading, setLoadingByContactMethod] = useState<LoadingContactMethod>({});

  const goToContactMethod = useNavigationByContactMethod(userItem);

  const clickHandlerFor = (contactMethod: ContactMethod) => async () => {
    setLoadingByContactMethod({ [contactMethod]: true });
    await updateLocation();
    setLoadingByContactMethod({ [contactMethod]: false });

    goToContactMethod(contactMethod)();
  };

  if (!userItem) {
    return null;
  }

  const { avatarUrl, description, user, status } = userItem;
  return (
    <div className={className}>
      <div className="contact-owner-container">
        <Avatar avatarUrl={avatarUrl} />
        <OwnerMesage message={description} firstName={user.firstName} status={status} />

        <div className="contact-owner-buttons-container">
          <Button
            loading={loading[ContactMethod.DirectMessage]}
            shape="round"
            className="message__button-container"
            onClick={clickHandlerFor(ContactMethod.DirectMessage)}
          >
            <img src="/assets/common/sosafe-icon.svg" alt="SOSAFE Message" />
            <span>{t('directMessageButton', { firstName: user.firstName })}</span>
          </Button>

          <Button
            loading={loading[ContactMethod.CreateReport]}
            shape="round"
            className="report__button-container"
            onClick={clickHandlerFor(ContactMethod.CreateReport)}
          >
            <img src="/assets/common/report-icon.svg" alt="SOSAFE create report" />
            <span>{t('reportButton')}</span>
          </Button>

          {userItem.user.phone && (
            <div className="contact-owner__other-means">
              <ContactByOtherMeans
                {...user}
                loading={loading[ContactMethod.OtherMeans]}
                onClick={clickHandlerFor(ContactMethod.OtherMeans)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type LoadingContactMethod = Partial<Record<ContactMethod, boolean>>;

interface Props {
  className?: string;
}

export default UserContactOwner;
