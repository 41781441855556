import styled from 'styled-components';
import SafetagFinalThankYou from './safetag-final-thank-you.component';

const SafetagFinalThankYouStyled = styled(SafetagFinalThankYou)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: 768px;
  margin: auto;
  padding: 16px;

  .container {
    margin: 48px 0px 0px;
    height: 70vh;
    min-height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__content, &__actions {
      font-family: Inter UI, Helvetica, Arial, sans-serif;
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      text-align: center;
    }
    &__content {
      &__img-container {
        margin: 0px 0px 24px;
        img {
          width: 75%;
          max-width 423px:
          height: auto;
        }
      }
      &__text-container {
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0px 0px 16px;
          letter-spacing: calc(1rem * -0.019);
        }
        p {
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: calc(1rem * -0.011);
        }
      }
    }
    &__actions {
      width: 100%;
      &__download-container {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        height: 12.5vh;
        min-height: 85px;
        max-height: 92px;
        margin: 8px 0px;
        width: 100%;
        div {
          margin: 0px 0px 8px;
        }
      }
      &__contact-again-container {
        margin: 8px 0px;
        width: 100%;
        button {
          width: 100%;
          font-size: 1rem;
          letter-spacing: calc(1rem * -0.011);
          font-weight: 400;
          border-color: transparent!important;
          box-shadow: none!important;
        }
      }
    }
  }
`;

export default SafetagFinalThankYouStyled;
