import { useTranslation } from 'react-i18next';
import joinUrl from 'url-join';

// Own
import { useSendMetricCreateReport } from '../use-send-metrics';
import { SOSAFE_DYNAMIC_LINK_CREATE_REPORT } from 'constants/env-variables';
import { Location } from '../type';
import useAndroidDeepLinkQParam from './use-android-deep-link-q-param';

export default function useGoToCreateReport(location?: Location) {
  const { t } = useTranslation('tag-found');
  const sendMetricCreateReport = useSendMetricCreateReport();
  const androidDeepLink = useAndroidDeepLinkQParam();

  return () => {
    sendMetricCreateReport();
    const description = t('reportCreation.message');

    window.location.href = joinUrl(
      `${SOSAFE_DYNAMIC_LINK_CREATE_REPORT}${encodeURI(description)}`,
      androidDeepLink,
      buildLocationQueryParam(location),
    );
  };
}

function buildLocationQueryParam(location?: Location) {
  return location ? `?latitude=${location.lat}&longitude=${location.long}` : '';
}
