export default {
  content: {
    title: '¡Quien encontró tu SAFE Tag podría contactarte!',
    description: {
      withLocation:
        'Alguien acaba de escanear tu SAFE Tag "{{-safe_tag_name}}" a las {{hour}} cerca de {{-location}} y es posible que intenten comunicarse contigo.',
      normal:
        'Alguien acaba de escanear tu SAFE Tag "{{-safe_tag_name}}" a las {{hour}} y es posible que intenten comunicarse contigo.',
    },
    disclaimer:
      'Es posible que te llamen, te envíen mensajes de texto o se pongan en contacto a través de mensajes privados de SOSAFE.',
  },
};
