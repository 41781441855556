import axios, { AxiosError, AxiosResponse, Method } from 'axios';

// Own
import { API_V3 } from 'constants/local-env-vars';

const client = axios.create({
  baseURL: API_V3,
});

export default async function request<T = any>(options: Options): Promise<T> {
  const onSuccess = (response: AxiosResponse) => {
    console.log('axios response', response);
    return response.data;
  };
  const onError = (error: AxiosError) => {
    console.log('axios error', error);
    return error?.response?.data;
  };

  const method = (options?.method as Method) || 'GET';

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  const fullOptions = { ...options, method, ...{ headers } };

  return await client(fullOptions).then(onSuccess).catch(onError);
}

export interface Options {
  url: string;
  method: string;
  headers?: Record<string, string>;
  /**
   * Json as string
   */
  data?: string;
}
