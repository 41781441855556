import { FunctionComponent } from 'react';

const ScannedImage: FunctionComponent = () => {
  return (
    <img
      style={{ maxWidth: '188px' }}
      src="/assets/scan-safetag/scanned-safetag.png"
      srcSet="/assets/scan-safetag/scanned-safetag.png 343w,
              /assets/scan-safetag/scanned-safetag@2x.png 686w,
              /assets/scan-safetag/scanned-safetag@3x.png 1029w"
      alt="SCANNED SAFETAG"
    />
  );
};

export default ScannedImage;
