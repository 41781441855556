export default {
  title: 'You’ve found {{firstName}}’s SAFE Tag!',
  subtitle:
    'Help {{firstName}} by sending the SAFE Tag location. We will only send this point of position.',
  buttons: {
    sendLocation: 'Send location and contact',
    dontSend: "Don't send, but contact",
  },
  actionUnavailable: 'Action can not be completed.',
  error: 'An error occurred, please try again!',
};
