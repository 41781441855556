import { FunctionComponent } from 'react';

// Own
import Pin from 'components/avatar/pin-svg';
import { useLocationUrl, useMapImgUrl } from './map-urls';

const LocationMap: FunctionComponent<Props> = ({ img, className, coordinates, ...props }) => {
  const locationUrl = useLocationUrl(coordinates);
  const mapUrl = useMapImgUrl(coordinates);

  return (
    <div className={className}>
      <a href={locationUrl} target="_blank" rel="noreferrer">
        <img src={mapUrl} alt={`SAFETAG location (${props.address})`} />
        <div className="location-map__pin-container">
          <div className="location-map__pin-inner-container">
            <div className="location-map__zone" />
            <Pin className="location-map__pin" url={img || '/assets/avatar.png'} />
          </div>
        </div>
      </a>
    </div>
  );
};

interface Props {
  className?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  address: string;
  img: string | null;
}

export default LocationMap;
