import request from 'utils/request';

export const getScanTracking = (uuid: string): Promise<Response> => {
  return request({
    method: 'GET',
    url: `/safetags/scan-tracking/${uuid}`,
  }).catch((error) => ({ success: false, error }));
};

export interface Response {
  success: boolean;
  data: Data;
  error?: string;
}

export interface Data {
  address: string | null;
  latitude: number | null;
  longitude: number | null;
}
