// Own
import { UserItem } from '../type';
import useGetKeysFromUrl from 'utils/use-get-keys-from-url';
import { useSendMetricContactByWhatsApp } from '../use-send-metrics';
import useRedirectToThanksPage from './use-redirect-to-thanks-page';
import useGetWhatsAppLink from '../use-get-whats-app-link';

export default function useGoToWhatsapp(userItem: UserItem) {
  const contactDeepLink = useGetKeysFromUrl('contactDeepLink');
  const sendMetricContactByWhatsApp = useSendMetricContactByWhatsApp();
  const redirectToThanksPage = useRedirectToThanksPage();
  const getWhatsAppLink = useGetWhatsAppLink();

  const { user } = userItem;

  return () => {
    sendMetricContactByWhatsApp();
    window.location.href = getWhatsAppLink({ phone: user.phone, firstName: user.firstName });
    redirectToThanksPage(user.firstName, contactDeepLink!);
  };
}
