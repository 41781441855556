export type Properties = Record<string, any>;
export interface LogEventParams {
  eventType: string;
  eventProperties?: Properties;
}

export type InitializeMetrics = () => void;
export type SetUserId = (userId: string) => void;
export type SetUserProperties = (properties: Properties) => void;
export type LogEvent = (options: LogEventParams) => void;

export interface MetricsProvider {
  initializeMetrics: InitializeMetrics;
  setUserId?: SetUserId;
  setUserProperties?: SetUserProperties;
  logEvent: LogEvent;
}

export enum EventTypes {
  WebviewScanQr = 'webview_scan_qr',
  QrContactWa = 'qr_contact_wa',
  QrContactCall = 'qr_contact_call',
  QrScanDownload = 'qr_scan_download',
  QrContactAgain = 'qr_contact_again',
  QrContactDm = 'qr_contact_dm',
  QrContactReport = 'qr_contact_report',
}
