import { useEffect, useState, useRef, useCallback } from 'react';

// Own
import { getScanTracking, Data } from 'services/scan-tracking/get';

export const useLoadScanTracking = (uuid: string | null) => {
  const requested = useRef(false);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<Data>({
    address: null,
    latitude: null,
    longitude: null,
  });

  const getScanTrackingData = useCallback(() => {
    setLoading(true);

    getScanTracking(uuid!)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [uuid]);

  useEffect(() => {
    if (requested.current) {
      return;
    }

    if (uuid) {
      requested.current = true;
      getScanTrackingData();
    }
  }, [getScanTrackingData, uuid]);

  return { data, loading };
};
