export default {
  title: 'Você encontrou a SAFE Tag de {{firstName}}!',
  ownerMessageLabel: '{{firstName}} deixou uma mensagem para você:',
  ownerleftMessage:
    'Informe {{firstName}} que você encontrou a SAFE Tag. Entre em contato o mais rápido possível com as seguintes opções:',
  whatsAppButton: 'Mensagem no WhatsApp',
  buttonCall: 'Ligue para {{firstName}}',
  joinCommunity: 'Por que não se juntar à comunidade?',
  downloadButton: 'Baixar SOSAFE',
  contactMessage: {
    message: 'Olá {{firstName}} 👋\nEncontrei sua SAFE Tag.',
  },
  reportCreation: {
    message:
      'Oi pessoal! Eu encontrei a SAFE Tag de alguém. Você pode me ajudar a entrar em contato com o proprietário?',
  },
  directMessageButton: 'Mensagem {{firstName}} no SOSAFE',
  reportButton: 'Relatório sobre SOSAFE',
  locationStatus: {
    title: {
      allow: 'Obrigada!',
      error: 'Ops... não foi possível enviar o local da SAFE Tag',
      deny: 'Entre em contato com o proprietário da SAFE Tag!',
    },
    description: {
      allow: 'O proprietário do SAFE Tag está agora aguardando seu contato.',
      error:
        'Não foi possível enviar a localização desta SAFE Tag ao proprietário. Tente enviar uma mensagem ou fazer uma chamada para o proprietário.',
      deny: 'Informe {{firstName}} que você encontrou a SAFE Tag. Entre em contato o mais rápido possível com as seguintes opções:',
    },
  },
  contactByOtherMeans: 'Contato por outros meios',
};
