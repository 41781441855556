// Own
import {
  CONTACT_HIGH_ACCURACY_LOCATION_TIMEOUT,
  CONTACT_LOW_ACCURACY_LOCATION_TIMEOUT,
} from 'constants/env-variables';

export const PERMISSION_DENIED = 'permission-denied';

export default async function fetchLocation(opts: {
  highAccuracy: boolean;
}): Promise<FetchLocationResult> {
  const timeout = getTimeoutByAccuracy(opts.highAccuracy);

  return new Promise((resolve) => {
    let resolved = false;

    const onSuccess: PositionCallback = (location) => {
      if (resolved) {
        return;
      }
      resolved = true;
      resolve(location || null);
    };

    const onError = (error: GeolocationPositionError) => {
      console.error(error);
      if (resolved) {
        return;
      }
      resolved = true;

      if (error?.code && error.code === error.PERMISSION_DENIED) {
        resolve(PERMISSION_DENIED);
      } else {
        resolve(null);
      }
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      maximumAge: 0,
      timeout: timeout,
      enableHighAccuracy: opts.highAccuracy,
    });

    setTimeout(() => {
      if (!resolved) {
        resolved = true;
        resolve(null);
      }
    }, timeout);
  });
}

function getTimeoutByAccuracy(highAccuracy: boolean) {
  return highAccuracy
    ? CONTACT_HIGH_ACCURACY_LOCATION_TIMEOUT
    : CONTACT_LOW_ACCURACY_LOCATION_TIMEOUT;
}

type FetchLocationResult = Promise<GeolocationPosition | typeof PERMISSION_DENIED | null>;

export function resultIsLocation(result: any): result is GeolocationPosition {
  return (result as GeolocationPosition)?.coords !== undefined;
}

export function resultIsPermissionError(result: any): result is typeof PERMISSION_DENIED {
  return result === PERMISSION_DENIED;
}
