import styled from 'styled-components';
import Avatar from './avatar.component';

const AvatarStyled = styled(Avatar)`
  display: flex;
  justify-content: center;
  position: relative;

  .safetag-avatar__container {
    z-index: 4;
    margin: 48px 0px;
  }
  .safetag-avatar__image {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
`;

export default AvatarStyled;
