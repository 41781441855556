import styled from 'styled-components';

//Own
import OwnerMessage from './owner-message.component';

const OwnerMessageStyled = styled(OwnerMessage)`
  font-size: 16px;
  text-align: center;
  padding-bottom: 8px;

  .owner-message__title {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }

  .owner-message__text {
    font-style: italic;
    font-weight: normal;
    color: #000000;
  }

  .owner-message__no-text {
    color: #787878;
  }

  .owner__message-label {
    font-weight: normal;
    color: #787878;
  }
`;
export default OwnerMessageStyled;
