export default {
  title: 'Vous avez trouvé le SAFE Tag de {{firstName}}!',
  subtitle:
    "Aidez {{firstName}} en envoyant l'emplacement de la SAFE Tag. Nous n'enverrons que ce point de position.",
  buttons: {
    sendLocation: "Envoyer l'emplacement et le contact",
    dontSend: "N'envoyez pas, mais contactez",
  },
  actionUnavailable: "L'action ne peut pas être terminée.",
  error: "Une erreur s'est produite, veuillez réessayer!",
};
