export default {
  title: '¡Has encontrado el SAFE Tag de {{firstName}}!',
  ownerMessageLabel: '{{firstName}} te ha dejado un mensaje:',
  ownerleftMessage:
    'Hazle saber a {{firstName}} que has encontrado su SAFE Tag. Por favor, póngase en contacto tan pronto como sea posible con las siguientes opciones:',
  whatsAppButton: 'Mensaje en WhatsApp',
  buttonCall: 'Llamar a {{firstName}}',
  joinCommunity: '¿Por qué no unirse a la comunidad?',
  downloadButton: 'Descargar SOSAFE',
  contactMessage: {
    message: 'Hola {{firstName}} 👋\nEncontré tu SAFE Tag.',
  },
  reportCreation: {
    message: '¡Hola a todos! Encontré el SAFE Tag de alguien. ¿Me ayudan a encontrar al dueño(a)?',
  },
  directMessageButton: 'Mensajear a {{firstName}} en SOSAFE',
  reportButton: 'Reportar en SOSAFE',
  locationStatus: {
    title: {
      allow: '¡Gracias!',
      error: 'Oops... no pudimos enviar la ubicación del SAFE Tag',
      deny: '¡Contacta a la persona dueña del SAFE Tag!',
    },
    description: {
      allow: 'El dueño del SAFE Tag está esperando ahora tu contacto.',
      error:
        'No pudimos enviar la ubicación del SAFE Tag a su dueño. Intenta enviar un mensaje o hacer una llamada al dueño.',
      deny: 'Hazle saber a {{firstName}} que encontraste su SAFE Tag. Por favor, ponte en contacto tan pronto como sea posible mediante las siguientes opciones:',
    },
  },
  contactByOtherMeans: 'Contactar por otro medio',
};
