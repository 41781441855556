//Own
import { useCallback } from 'react';
import { EventTypes } from 'utils/metrics/types';
import { useLogEvent } from 'utils/metrics/use-metrics';

export const useSendMetricContactAgain = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({ eventType: EventTypes.QrContactAgain });
  }, [logEvent]);
};

export const useSendMetricDownloadApp = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: EventTypes.QrScanDownload,
      eventProperties: {
        source: 'Webview Safetag Final',
      },
    });
  }, [logEvent]);
};
