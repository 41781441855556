import styled from 'styled-components';
import AndroidDownloadAplication from './android-download-aplication';

export default styled(AndroidDownloadAplication)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: 480px;
  margin: auto;
  padding: 0 16px;

  .download-application__logo-container {
    margin: 52px 0px 28px 0px;
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.019em;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    margin-bottom: 15%;
    color: #787878;
  }

  .download-application__buttons-container {
    width: 100%;
    .download__button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      svg {
        margin-right: 14px;
        align-self: center;
        height: 100%;
      }
      span  {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;
