import styled from 'styled-components';
import ScannedSafetag from './scanned-safetag.component';

const ScannedSafetagStyled = styled(ScannedSafetag)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: auto;
  padding: 16px;

  .container {
    margin: 24px 0px 0px;
    height: 70vh;
    min-height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      font-family: Inter UI, Helvetica, Arial, sans-serif;
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      text-align: center;
    }
    &__content {
      &__img-container {
        margin: 0px 0px 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .container__img {
          width: 100%;
          max-width: 480px;
          height: auto;
        }
      }
      &__text-container {
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0px 0px 16px;
          letter-spacing: calc(1rem * -0.019);
        }
        p {
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: calc(1rem * -0.011);
        }
      }
      &__primary-paragraph {
        font-style: normal;
        font-weight: normal;
        letter-spacing: -0.011em;
        color: #000000;

        span {
          color: #f54286;
        }
      }
      &__secondary-paragraph {
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.011em;
        color: #000000;
      }
    }
  }
`;

export default ScannedSafetagStyled;
