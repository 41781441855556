import { Button } from 'antd';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

//Own
import { SOSAFE_GOOGLE_PLAY_URL, SOSAFE_APP_GALLERY_URL } from 'constants/env-variables';
import { ReactComponent as DownloadSosafe } from './download_sosafe.svg';
import { ReactComponent as GooglePlay } from './google-play.svg';
import { ReactComponent as AppGallery } from './app-gallery.svg';

const AndroidDownloadApplication: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('download-aplication');

  return (
    <div className={className}>
      <div className="download-application__logo-container">
        <DownloadSosafe />
      </div>
      <h1>{t('title')}</h1>
      <p>{t('description')}</p>
      <div className="download-application__buttons-container">
        <Button
          shape="round"
          className="download__button"
          onClick={() => {
            window.location.href = SOSAFE_GOOGLE_PLAY_URL;
          }}
        >
          <GooglePlay />
          <span>{t('googlePlayButton')}</span>
        </Button>
        <Button
          shape="round"
          className="download__button"
          onClick={() => {
            window.location.href = SOSAFE_APP_GALLERY_URL;
          }}
        >
          <AppGallery />
          <span>{t('appGalleryButton')}</span>
        </Button>
      </div>
    </div>
  );
};

interface Props {
  className?: string;
}

export default AndroidDownloadApplication;
