export default {
  title: 'Vous avez trouvé le SAFE Tag de {{firstName}}!',
  ownerMessageLabel: '{{firstName}} a laissé un message pour vous:',
  ownerleftMessage:
    "Faites savoir à {{firstName}} que vous avez trouvé la SAFE Tag. S'il vous plaît contacter dès que possible avec les options suivantes:",
  whatsAppButton: 'Message sur WhatsApp',
  buttonCall: 'Appelez {{firstName}}',
  joinCommunity: 'Pourquoi ne pas rejoindre la communauté?',
  downloadButton: 'Télécharger SOSAFE',
  contactMessage: {
    message: "Bonjour {{firstName}} 👋\nJ'ai trouvé votre SAFE Tag.",
  },
  reportCreation: {
    message:
      "Salut tout le monde! J'ai trouvé le SAFE Tag de quelqu'un. Pouvez-vous m'aider à contacter le propriétaire?",
  },
  directMessageButton: 'Message {{firstName}} sur SOSAFE',
  reportButton: 'Rapport sur SOSAFE',
  locationStatus: {
    title: {
      allow: 'Merci!',
      error: "Oups... nous n'avons pas pu envoyer l'emplacement du SAFE Tag",
      deny: 'Contactez le propriétaire du SAFE Tag!',
    },
    description: {
      allow: 'Le propriétaire du SAFE Tag attend maintenant votre contact.',
      error:
        "Nous n'avons pas pu envoyer l'emplacement de ce SAFE Tag à son propriétaire. Essayez d'envoyer un message ou d'appeler le propriétaire.",
      deny: "Faites savoir à {{firstName}} que vous avez trouvé la SAFE Tag. S'il vous plaît contacter dès que possible avec les options suivantes:",
    },
  },
  contactByOtherMeans: "Contacter par d'autres moyens",
};
