import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Own
import { useMetricsInitializer } from 'utils/metrics/use-metrics';
import store from 'state';
import NoUserContactOwner from 'core/contact-owner/without-app';
import UserContactOwner from 'core/contact-owner/with-app';
import SafetagFinalThankYou from 'core/safetag-final-thank-you';
import SafetagNotLinkedWithApp from 'core/safetag-not-linked-with-app';
import SafetagNotLinkedWithoutApp from 'core/safetag-not-linked-without-app';
import ScannedSafetag from 'core/scanned-safetag';
import ContactRedirection from 'core/contact-redirection';
import DownloadApplication from 'core/download-application';
import AndroidDownloadAplication from 'core/android-download-aplication';

function App() {
  const metricsInitializer = useMetricsInitializer();

  useLayoutEffect(() => {
    metricsInitializer();
  }, [metricsInitializer]);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            exact
            path="/:lng/public/contact/:encodedUserItem"
            component={NoUserContactOwner}
          />
          <Route
            exact
            path="/:lng/contact/:encodedUserItem/:isAndroid?"
            component={UserContactOwner}
          />
          <Route exact path="/:lng/claim/:uuid" component={SafetagNotLinkedWithApp} />
          <Route exact path="/:lng/public/claim/:uuid" component={SafetagNotLinkedWithoutApp} />
          <Route exact path="/:lng/public/thanks" component={SafetagFinalThankYou} />
          <Route
            exact
            path="/:lng/public/android-download-page"
            component={AndroidDownloadAplication}
          />
          <Route exact path="/:lng/public/scanned" component={ScannedSafetag} />
          <Route exact path="/contact/:appLinkUrls" component={ContactRedirection} />
          <Route exact path="*" component={DownloadApplication} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
