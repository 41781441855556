// Own
import { UserItem } from '../type';
import useGetKeysFromUrl from 'utils/use-get-keys-from-url';
import { useSendMetricContactByCall } from '../use-send-metrics';
import useRedirectToThanksPage from './use-redirect-to-thanks-page';

export default function useGoToCall(userItem: UserItem) {
  const contactDeepLink = useGetKeysFromUrl('contactDeepLink');
  const sendMetricContactByCall = useSendMetricContactByCall();
  const redirectToThanksPage = useRedirectToThanksPage();

  const { user } = userItem;

  return () => {
    sendMetricContactByCall();
    window.location.href = `tel:${user.phone}`;
    redirectToThanksPage(user.firstName, contactDeepLink!);
  };
}
