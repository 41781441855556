// Own
import { usePathParam } from 'utils/use-get-keys-from-url';
import { UserItem } from './type';
import { deobfuscate } from 'utils/string-obfuscators';
import { useCallback } from 'react';

export const USER_ITEM = 'encodedUserItem';

export function useGetUserItem() {
  const userItemBase64 = usePathParam(USER_ITEM);

  return useCallback(() => {
    if (userItemBase64) {
      return JSON.parse(deobfuscate(userItemBase64)) as UserItem;
    }

    return null;
  }, [userItemBase64]);
}
