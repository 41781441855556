import { useCallback, useState } from 'react';

// Own
import { UseGetLocationOutput } from './types';
import fetchLocation, { resultIsLocation, resultIsPermissionError } from './fetch-location';

export default function useGetLocation(): UseGetLocationOutput {
  const [gettingLocation, setGettingLocation] = useState(false);

  const getLocation = useCallback(async () => {
    setGettingLocation(true);

    const location = await getLocationChangingAccuracy().finally(() => {
      setGettingLocation(false);
    });

    return location;
  }, []);

  return { gettingLocation, getLocation };
}

async function getLocationChangingAccuracy() {
  let result = await fetchLocation({ highAccuracy: true }).catch(() => null);
  if (resultIsLocation(result)) {
    return result;
  }

  if (resultIsPermissionError(result)) {
    return null;
  }

  // We only try the low accuracy location if the user didn't block permissions
  // or if previously it failed by timeout
  result = await fetchLocation({ highAccuracy: false }).catch(() => null);

  return resultIsLocation(result) ? result : null;
}
