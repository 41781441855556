export default {
  title: 'You’ve found {{firstName}}’s SAFE Tag!',
  ownerMessageLabel: '{{firstName}} left a message for you:',
  ownerleftMessage:
    'Let {{firstName}} know that you’ve found the SAFE Tag. Please contact as soon as possible with the following options:',
  whatsAppButton: 'Message on WhatsApp',
  buttonCall: 'Call {{firstName}}',
  joinCommunity: 'Why not join the community?',
  downloadButton: 'Download SOSAFE',
  contactMessage: {
    message: 'Hi {{firstName}} 👋\nI found your SAFE Tag.',
  },
  reportCreation: {
    message:
      'Hi everybody! I have found the SAFE Tag of someone. Can you help me to contact the owner?',
  },
  directMessageButton: 'Message {{firstName}} on SOSAFE',
  reportButton: 'Report on SOSAFE',
  locationStatus: {
    title: {
      allow: 'Thank you!',
      error: "Oops... we couldn't send the SAFE Tag location",
      deny: 'Contact the SAFE Tag owner!',
    },
    description: {
      allow: 'The owner of the SAFE Tag is now waiting for your contact.',
      error:
        "We couldn't send the location of this SAFE Tag to its owner. Try sending a message or making a call to the owner.",
      deny: 'Let {{firstName}} know that you’ve found the SAFE Tag. Please contact as soon as possible with the following options:',
    },
  },
  contactByOtherMeans: 'Contact by other means',
};
