export default {
  title: '¡Has encontrado el SAFE Tag de {{firstName}}!',
  subtitle:
    'Ayuda a {{firstName}} enviando la ubicación del SAFE Tag. Sólo enviaremos este punto de posición.',
  buttons: {
    sendLocation: 'Enviar ubicación y contactar',
    dontSend: 'No enviar, pero contactar',
  },
  actionUnavailable: 'La acción no se puede completar.',
  error: 'Ocurrió un error. ¡Por favor intenta de nuevo!',
};
