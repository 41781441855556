import styled from 'styled-components';
import LocationMap from './location-map.component';

export default styled(LocationMap)`
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .location-map__pin-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;

    .location-map__pin-inner-container {
      width: 100%;
      height: 100%;
      position: relative;

      .location-map__zone {
        background-color: rgba(245, 66, 134, 0.35);
        height: 115px;
        width: 115px;
        border-radius: 50%;
        position: absolute;
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 360px) {
          height: 100px;
          width: 100px;
          bottom: -24px;
        }
      }

      .location-map__pin {
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%) scale(0.5);
      }
    }
  }
`;
