export interface Props {
  className?: string;
}

export interface ContactOwnerPayload {
  phone: string;
  userFirstName: string;
}

export enum ContactMethod {
  Whatsapp,
  Call,
  Download,
}

export type LoadingContactMethod = Partial<Record<ContactMethod, boolean>>;
