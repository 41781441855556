interface SafeTag {
  uuid: string;
}
interface User {
  uuid: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface Location {
  lat: number;
  long: number;
  accuracy: number;
}

export interface UserItem {
  name: string;
  description: string;
  avatarUrl: string;
  user: User;
  safetag: SafeTag;
  status: ShareLocationStatus;
  location?: Location;
  scanTrackingUuid: string;
}

export enum ShareLocationStatus {
  Allow = 'allow',
  Deny = 'deny',
  Error = 'error',
}
