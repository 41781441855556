import { FunctionComponent } from 'react';

const PinSvg: FunctionComponent<Props> = ({ url, className }) => {
  return (
    <svg
      width={115}
      height={124}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <ellipse cx={57.108} cy={114.217} rx={8.786} ry={4.393} fill="#000" fillOpacity={0.24} />
      <g filter="url(#a)">
        <circle cx={57.108} cy={54.912} r={48.322} fill="url(#safetag_avatar_pattern)" />
        <circle cx={57.108} cy={54.912} r={45.577} stroke="#fff" strokeWidth={5.491} />
      </g>
      <path
        // eslint-disable-next-line max-len
        d="M57.108 103.235c-2.744 0-5.434-.229-8.054-.668l7.32 9.758a.918.918 0 0 0 1.467 0l7.32-9.758c-2.618.439-5.309.668-8.053.668Z"
        fill="#fff"
      />
      <defs>
        <filter
          id="a"
          x={0}
          y={4.393}
          width={114.217}
          height={114.217}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={6.589} />
          <feGaussianBlur stdDeviation={4.393} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_8483_218" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_8483_218" result="shape" />
        </filter>

        <pattern
          id="safetag_avatar_pattern"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use href="#safetag_avatar" transform="scale(0.001)" />
        </pattern>
        <image
          preserveAspectRatio="xMidYMid slice"
          id="safetag_avatar"
          width="1000"
          height="1000"
          href={url}
        ></image>
      </defs>
    </svg>
  );
};

interface Props {
  className?: string;
  url: string;
}

export default PinSvg;
