import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import Div100vh from 'react-div-100vh';

// Own
import { useSendMetricWebviewScanQr } from './use-send-metrics';
import HeartImage from './heart-image';
import { SOSAFE_DYNAMIC_LINK_CLAIM_SAFETAG } from 'constants/env-variables';
import { usePathParam } from 'utils/use-get-keys-from-url';

const SafetagNotLinkedWithApp: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('safetag-not-linked-with-app');
  useSendMetricWebviewScanQr();
  const safetagIdentifier = usePathParam('uuid');

  const handlerLinkSafeTag = () => {
    window.location.href = `${SOSAFE_DYNAMIC_LINK_CLAIM_SAFETAG}${safetagIdentifier}`;
  };

  return (
    <Div100vh>
      <div className={className}>
        <div className="safetag-not-linked__superior-container">
          <div className="safetag-not-linked__superior-container__img-container">
            <HeartImage />
          </div>
          <div className="safetag-not-linked__superior-container__text-container">
            <h1>{t('onboarding.title')}</h1>
            <Typography.Paragraph type="secondary">
              {t('onboarding.description')}
            </Typography.Paragraph>
          </div>
        </div>
        <div className="safetag-not-linked__inferior-container">
          <div className="safetag-not-linked__inferior-container__button-container">
            <div className="safetag-not-linked__disclaimer__container">
              <img
                className={'safetag-not-linked__disclaimer__icon'}
                src="/assets/common/alert-circle.svg"
                alt="Alert Icon"
              />
              <Typography.Paragraph className="safetag-not-linked__disclaimer__text">
                {t('disclaimerSosafe')}
              </Typography.Paragraph>
            </div>
            <Button type="primary" shape="round" onClick={handlerLinkSafeTag}>
              {t('linkSafetag')}
            </Button>
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

interface Props {
  className?: string;
}

export default SafetagNotLinkedWithApp;
