import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styled from 'styled-components';

// Own
import useGetWhatsAppLink from 'core/contact-owner/use-get-whats-app-link';

const ContactByOtherMeans: FunctionComponent<Props> = ({
  className,
  firstName,
  phone,
  loading,
  onClick,
}) => {
  const { t } = useTranslation('tag-found');
  const link = useGetWhatsAppLink()({
    firstName,
    phone,
  });

  return (
    <Button className={className} loading={loading} type="link" href={link} onClick={onClick}>
      {t('contactByOtherMeans')}
    </Button>
  );
};

interface Props {
  className?: string;
  firstName: string;
  phone: string;
  loading?: boolean;
  onClick?: () => void;
}

export default styled(ContactByOtherMeans)`
  color: #787878;

  span {
    text-decoration: underline;
  }
`;
