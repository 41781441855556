import { useCallback } from 'react';

// Own
import { useLogEvent } from 'utils/metrics/use-metrics';
import { EventTypes as MetricsEventTypes } from 'utils/metrics/types';

export const useSendMetricDownloadApp = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: MetricsEventTypes.QrScanDownload,
      eventProperties: {
        source: 'Fallback webview to download application',
      },
    });
  }, [logEvent]);
};
