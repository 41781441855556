import { useCallback, useEffect } from 'react';

// Own
import { useLogEvent } from 'utils/metrics/use-metrics';
import { EventTypes as MetricsEventTypes } from 'utils/metrics/types';

export const useSendMetricSafetagNotLinkedNoUser = () => {
  const logEvent = useLogEvent();
  useEffect(() => {
    logEvent({
      eventType: MetricsEventTypes.WebviewScanQr,
      eventProperties: {
        user_app_sosafe: false,
        tag_related: false,
      },
    });
  }, [logEvent]);
};

export const useSendMetricDownloadApp = () => {
  const logEvent = useLogEvent();
  return useCallback(() => {
    logEvent({
      eventType: MetricsEventTypes.QrScanDownload,
      eventProperties: {
        source: 'Webview Safetag not linked without app',
      },
    });
  }, [logEvent]);
};
