import amplitude from 'amplitude-js';

// Own
import { AMPLITUDE_API_KEY } from 'constants/env-variables';
import {
  InitializeMetrics,
  SetUserId,
  SetUserProperties,
  LogEvent,
  MetricsProvider,
} from 'utils/metrics/types';

export default class AmplitudeMetricsProvider implements MetricsProvider {
  initializeMetrics: InitializeMetrics = () => {
    amplitude.getInstance().init(AMPLITUDE_API_KEY as string);
  };

  setUserId: SetUserId = (userId) => {
    amplitude.getInstance().setUserId(userId);
  };

  setUserProperties: SetUserProperties = (properties) => {
    amplitude.getInstance().setUserProperties(properties);
  };

  logEvent: LogEvent = ({ eventType, eventProperties }) => {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  };
}

let amplitudSingleton: AmplitudeMetricsProvider | null = null;
export const getAmplitudSingleton = () => {
  if (amplitudSingleton) {
    return amplitudSingleton;
  }

  amplitudSingleton = new AmplitudeMetricsProvider();
  return amplitudSingleton;
};
