import { useCallback, useState } from 'react';

// Own
import { API_V3 } from 'constants/local-env-vars';
import { SendLocation, SendLocationOptions } from './types';

export default function useSendLocation() {
  const [sending, setSending] = useState(false);

  const sendLocation: SendLocation = useCallback(async (opts: SendLocationOptions) => {
    if (invalidLocation(opts)) {
      return;
    }

    setSending(true);

    await fetch(getUrl(opts), {
      method: 'PUT',
      body: getPayload(opts),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.error('Error sending location', err);
      })
      .finally(() => {
        setSending(false);
      });
  }, []);

  return { sending, sendLocation };
}

function invalidLocation(opts: SendLocationOptions) {
  return !opts.location || !opts.location.coords;
}

function getUrl(opts: SendLocationOptions) {
  return `${API_V3}/safetags/scan-tracking/${opts.scanTrackingUuid}`;
}

function getPayload(opts: SendLocationOptions) {
  return JSON.stringify({
    lat: opts.location!.coords.latitude,
    lng: opts.location!.coords.longitude,
    accuracy: opts.location!.coords.accuracy,
  });
}
