import styled from 'styled-components';
import DownloadApplication from './download-application.component';

export default styled(DownloadApplication)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: 768px;
  margin: auto;
  padding: 0 16px;
  .download-application__logo-container {
    margin: 52px 0;
  }
  .download-application__text-container {
    text-align: center;
    font-family: Inter UI, Helvetica, Arial, sans-serif;
    letter-spacing: calc(1rem * -0.011);
    font-weight: 400;

    h1 {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
  .download-application__img-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    img {
      margin: 16px;
      width: 100%;
    }
  }
`;
