//Own
import { useEffect } from 'react';
import { EventTypes } from 'utils/metrics/types';
import { useLogEvent } from 'utils/metrics/use-metrics';

export const useSendMetricWebviewScanQr = () => {
  const logEvent = useLogEvent();
  return useEffect(() => {
    logEvent({
      eventType: EventTypes.WebviewScanQr,
      eventProperties: {
        user_app_sosafe: true,
        tag_related: false,
      },
    });
  }, [logEvent]);
};
