import styled from 'styled-components';

//Own
import OpenAppBanner from './open-app-banner';

export default styled(OpenAppBanner)`
  display: flex;
  justify-content: center;
  left: 0px;
  z-index: 9999;
  position: absolute;
  width: 100%;
  background-color: white;

  .banner__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 480px;
    width: 100%;
    padding: 8px 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  }

  .sosafe-app__icon-container {
    display: flex;
    align-items: center;
    .sosafe-app__icon {
      width: 40px;
      height: 40px;
    }
  }

  .body-text-container {
    margin: 0px 8px;
    flex: 1;
    div {
      margin: 0px;
      font-size: 16px;
      line-height: 20px;
    }
    .body-text__title {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }
    .body-text__subtitle {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .contact-owner__button-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .contact-owner__text-button {
      font-size: 16px;
      padding: 3px 13px;
      color: #ffffff;
      background: #f54286;
      border-radius: 24px;
    }
  }
`;
