import { useTranslation } from 'react-i18next';

// Own
import { GOOGLE_MAPS_API_KEY } from 'constants/env-variables';

export function useMapImgUrl({ latitude, longitude }: Coordinates) {
  const language = useTranslation().i18n.language;
  const params: Record<string, string> = {
    center: `${latitude},${longitude}`,
    zoom: '17',
    // 16:9 aspect ratio for md screen sizes according to antd docs
    size: '576x324',
    language,
    key: GOOGLE_MAPS_API_KEY,
  };

  const queryParams = Object.keys(params)
    .map((x) => `${x}=${params[x]}`)
    .join('&');

  return `https://maps.googleapis.com/maps/api/staticmap?${queryParams}`;
}

export function useLocationUrl({ latitude, longitude }: Coordinates) {
  const coordinates = encodeURIComponent(`${latitude},${longitude}`);
  return `https://www.google.com/maps/search/?api=1&query=${coordinates}`;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}
