export default {
  content: {
    title: 'O localizador pode entrar em contato com você!',
    description: {
      withLocation:
        'Alguém acabou de verificar sua SAFE Tag "{{-safe_tag_name}}" às {{hour}} perto de {{-location}} e é possível que eles tentem entrar em contato com você.',
      normal:
        'Alguém acabou de verificar sua etiqueta SAFE "{{-safe_tag_name}}" às {{hour}} e é possível que eles tentem entrar em contato com você.',
    },
    disclaimer:
      'Eles podem ligar para você, enviar mensagens de texto ou entrar em contato com você por meio de mensagens privadas SOSAFE.',
  },
};
