import styled from 'styled-components';
import NoUserContactOwner from './no-user-contact-owner.component';

const NoUserContactOwnerStyled = styled(NoUserContactOwner)`
  font-family: Inter UI;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0px 16px;
  position: relative;

  .contact-owner-container {
    flex: 0.88;
    .contact-owner__title {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      color: #000000;
    }
  }

  .contact-owner-buttons-container {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    .whats-app__button-container {
      width: 100%;
      padding: 13px 16px;
      display: flex;
      justify-content: center;
      background: #27b43e;
      border-radius: 8px;
      margin: 12px 0px;
      cursor: pointer;

      &:hover {
        color: unset;
        border: none;
      }

      span {
        text-decoration: none;
        text-align: center;
        color: white;
        padding-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .call__button-container {
      width: 100%;
      padding: 13px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #dfdfdf;
      box-sizing: border-box;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      margin: 12px 0px;
      cursor: pointer;

      span {
        text-decoration: none;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        padding-left: 8px;
      }
    }
  }

  .contact-owner__download-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .join-community__text {
      font-weight: normal;
      font-size: 16px;
      color: #787878;
      text-align: center;
    }
  }
`;

export default NoUserContactOwnerStyled;
