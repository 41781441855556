import joinUrl from 'url-join';

// Own
import { useSendMetricMessageToOwner } from '../use-send-metrics';
import { SOSAFE_DYNAMIC_LINK_DIRECT_MESSAGE } from 'constants/env-variables';
import useAndroidDeepLinkQParam from './use-android-deep-link-q-param';

export default function useGoToDirectMessage(ownerUuid: string) {
  const sendMetricMessageToOwner = useSendMetricMessageToOwner();
  const androidDeepLink = useAndroidDeepLinkQParam();

  return () => {
    sendMetricMessageToOwner();
    window.location.href = joinUrl(
      `${SOSAFE_DYNAMIC_LINK_DIRECT_MESSAGE}${ownerUuid}`,
      androidDeepLink,
    );
  };
}
