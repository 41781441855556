import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

//Own
import { getLang } from 'utils/i18n';
import { ReactComponent as SosafeIcon } from './sosafe-icon.svg';

const WAITING_TIME_FOR_REDIRECT_DOWNLOAD_PAGE = 500;
const OpenAppBanner: FunctionComponent<Props> = ({ className, contactDeepLink }) => {
  const { t } = useTranslation('open-app-banner');

  const redirectToDownLoadPage = useRedirectToDownLoadPage();
  const redirecToDeepLink = () => {
    window.location.href = contactDeepLink;
    redirectToDownLoadPage();
  };

  if (contactDeepLink)
    return (
      <div className={className}>
        <div className="banner__container">
          <div className="sosafe-app__icon-container">
            <SosafeIcon className="sosafe-app__icon" />
          </div>
          <div className="body-text-container">
            <div className="body-text__title">{t('title')}</div>
            <div className="body-text__subtitle">{t('subtitle')}</div>
          </div>
          <div className="contact-owner__button-container" onClick={redirecToDeepLink}>
            <span className="contact-owner__text-button">{t('action')}</span>
          </div>
        </div>
      </div>
    );

  return null;
};

function useRedirectToDownLoadPage() {
  const history = useHistory();
  return () => {
    const lang = getLang();
    setTimeout(() => {
      history.push(`/${lang}/public/android-download-page`);
    }, WAITING_TIME_FOR_REDIRECT_DOWNLOAD_PAGE);
  };
}

export default OpenAppBanner;

interface Props {
  className?: string;
  contactDeepLink: string;
}
