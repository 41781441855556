// Own
import { ContactMethod } from './types';
import useGoToDirectMessage from './use-go-to-direct-message';
import useGoToCreateReport from './use-go-to-create-report';
import { UserItem } from 'core/contact-owner/type';

export default function useNavigationByContactMethod(userItem: UserItem) {
  const goToDirectMessage = useGoToDirectMessage(userItem?.user.uuid!);
  const goToCreateReport = useGoToCreateReport(userItem?.location);

  return (contactMethod: ContactMethod) => {
    switch (contactMethod) {
      case ContactMethod.DirectMessage:
        return goToDirectMessage;
      case ContactMethod.CreateReport:
        return goToCreateReport;
      default:
        return () => {};
    }
  };
}
