import styled, { css } from 'styled-components';
import DownloadAppButton from './download-app-button.component';

export default styled(DownloadAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.size === 'small' ? '26px' : '52px')};
  width: 100%;
  font-weight: 500;
  border-radius: ${(props) => props.shape === 'round' && '8px'};
  color: #f54286;
  ${(props) =>
    (props.type === 'primary' || !props.type) &&
    css`
      background-color: #f54286;
      color: white;
      &:hover {
        background-color: #ff6ea1;
        color: white;
      }
    `}
  ${(props) =>
    props.type === 'link' &&
    css`
      &:hover {
        color: #6e788f;
      }
    `}
  span {
    padding-left: 8px;
  }
`;
