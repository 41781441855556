import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';

// Own
import { Langs } from './i18n';

export function useGetLang() {
  const { lng: lang } = useParams<{
    lng: string;
  }>();
  return lang as Langs;
}

export function usePathParam(param: string) {
  const queryParams = useParams<Record<any, string>>();
  if (!_.has(queryParams, param)) return null;
  return queryParams[param];
}

export default function useGetKeysFromUrl(param: string): string | null {
  const searchParams = new URLSearchParams(useLocation().search);
  return searchParams.get(param);
}
