import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

// Own
import { useSendMetricSafetagNotLinkedNoUser, useSendMetricDownloadApp } from './use-send-metrics';
import DownloadAppButton from 'components/download-app/button';

const SafetagNotLinkedWithoutApp: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('safetag-not-linked');
  useSendMetricSafetagNotLinkedNoUser();
  const sendMetricDownloadApp = useSendMetricDownloadApp();

  const onDownloadAppButton = useCallback(() => {
    sendMetricDownloadApp();
  }, [sendMetricDownloadApp]);

  return (
    <div className={className}>
      <div className="safetag-not-linked__logo-container">
        <img src="/assets/common/logo-vertical.svg" alt="SOSAFE" />
      </div>
      <div className="safetag-not-linked__text-container">
        <h1>{t('title')}</h1>
        <Typography.Paragraph type="secondary">
          {t('subtitle.isNotSosafeUser')}
        </Typography.Paragraph>
        <DownloadAppButton type="primary" shape="round" onClick={onDownloadAppButton}>
          {t('downloadApp')}
        </DownloadAppButton>
      </div>
      <div className="safetag-not-linked__img-container">
        <img
          src="/assets/scan-safetag/items.png"
          srcSet="/assets/scan-safetag/items.png 343w,
                /assets/scan-safetag/items@2x.png 686w"
          alt="Items"
        />
      </div>
    </div>
  );
};

interface Props {
  className?: string;
}

export default SafetagNotLinkedWithoutApp;
