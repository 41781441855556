import {
  InitializeMetrics,
  LogEvent,
  SetUserId,
  SetUserProperties,
  MetricsProvider as ITMetricsProvider,
} from './types';

import { getAmplitudSingleton } from './amplitude';
const METRICS = [getAmplitudSingleton()];

export default class MetricsProvider implements ITMetricsProvider {
  initializeMetrics: InitializeMetrics = () => {
    METRICS.forEach((tracker) => {
      tracker.initializeMetrics();
    });
  };
  logEvent: LogEvent = (options) => {
    METRICS.forEach((tracker) => {
      tracker.logEvent(options);
    });
  };
  setUserId: SetUserId = (userId) => {
    METRICS.forEach((tracker) => {
      tracker.setUserId(userId);
    });
  };
  setUserProperties: SetUserProperties = (userProperties) => {
    METRICS.forEach((tracker) => {
      tracker.setUserProperties(userProperties);
    });
  };
}

let metricsProviderSingleton: MetricsProvider | null = null;
export const getMetricsProvider = () => {
  if (metricsProviderSingleton) {
    return metricsProviderSingleton;
  }

  metricsProviderSingleton = new MetricsProvider();
  return metricsProviderSingleton;
};
