import { useTranslation } from 'react-i18next';
import { FunctionComponent, useCallback } from 'react';
import { Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

// Own
import { useGetOwnerFirstName } from './use-get-owner-first-name';
import { useSendMetricContactAgain, useSendMetricDownloadApp } from './use-send-metrics';
import HeartImage from './heart-image';
import DownloadAppButton from 'components/download-app/button';
import useGetDownloadAppUrl from 'core/use-get-down-load-app-url';
import useGetKeysFromUrl from 'utils/use-get-keys-from-url';

const SafetagFinalThankYou: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('safetag-final-thank-you');
  const sendMetricContactAgain = useSendMetricContactAgain();
  const sendMetricDownloadApp = useSendMetricDownloadApp();
  const getOwnerFirstName = useGetOwnerFirstName();
  const history = useHistory();
  const isAndroid = useGetKeysFromUrl('isAndroid') === '1';

  const historyGoBack = useCallback(() => history.goBack(), [history]);

  const onContactAgain = useCallback(() => {
    historyGoBack();
    sendMetricContactAgain();
  }, [historyGoBack, sendMetricContactAgain]);

  const handlerDownloadApp = useCallback(() => {
    sendMetricDownloadApp();
  }, [sendMetricDownloadApp]);

  const firstName = getOwnerFirstName();
  const getDownloadAppUrl = useGetDownloadAppUrl(isAndroid);

  return (
    <div className={className}>
      <div className="container">
        <div className="container__content">
          <div className="container__content__img-container">
            <HeartImage />
          </div>
          <div className="container__content__text-container">
            <h1>{t('content.title')}</h1>
            <Typography.Paragraph type="secondary">
              {t('content.description', { firstName })}
            </Typography.Paragraph>
          </div>
        </div>
        <div className="container__actions">
          <div className="container__actions__download-container">
            <Typography.Paragraph type="secondary">{t('joinCommunity')}</Typography.Paragraph>
            <DownloadAppButton
              type="primary"
              shape="round"
              onClick={handlerDownloadApp}
              downloadAppUrl={getDownloadAppUrl()}
            >
              {t('downloadButton')}
            </DownloadAppButton>
          </div>
          <div className="container__actions__contact-again-container">
            <Button type="primary" ghost shape="round" onClick={() => onContactAgain()}>
              {t('contactAgain', { firstName })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  className?: string;
}

export default SafetagFinalThankYou;
