export default {
  content: {
    title: 'Le chercheur pourrait vous contacter!',
    description: {
      withLocation:
        'Quelqu\'un vient de scanner votre SAFE Tag "{{-safe_tag_name}}" à {{hour}} près de {{-location}} et il est possible qu\'il essaie de vous joindre.',
      normal:
        'Quelqu\'un vient de scanner votre SAFE Tag "{{-safe_tag_name}}" à {{hour}} et il est possible qu\'il essaie de vous joindre.',
    },
    disclaimer:
      'Ils peuvent vous appeler, vous envoyer des SMS ou vous contacter via les messages privés SOSAFE.',
  },
};
