/* eslint-disable max-len */
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY as string;
export const WHATS_APP_API = process.env.REACT_APP_WHATS_APP_API as string;
export const BASE_FULL_API_V3 = process.env.REACT_APP_BASE_FULL_API_V3 as string;
export const SOSAFE_DYNAMIC_LINK_DOWNLOAD_APP =
  process.env.REACT_APP_SOSAFE_DYNAMIC_LINK_DOWNLOAD_APP;
export const SOSAFE_DYNAMIC_LINK_CREATE_REPORT = `${BASE_FULL_API_V3}${process.env.REACT_APP_SOSAFE_DYNAMIC_LINK_CREATE_REPORT}`;
export const SOSAFE_DYNAMIC_LINK_DIRECT_MESSAGE = `${BASE_FULL_API_V3}${process.env.REACT_APP_SOSAFE_DYNAMIC_LINK_DIRECT_MESSAGE}`;
export const SOSAFE_DYNAMIC_LINK_CLAIM_SAFETAG = `${BASE_FULL_API_V3}${process.env.REACT_APP_SOSAFE_DYNAMIC_LINK_CLAIM_SAFETAG}`;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const SOSAFE_GOOGLE_PLAY_URL = process.env.REACT_APP_SOSAFE_GOOGLE_PLAY_URL as string;
export const SOSAFE_APP_GALLERY_URL = process.env.REACT_APP_SOSAFE_APP_GALLERY_URL as string;

/**
 * Milliseconds
 */
export const CONTACT_HIGH_ACCURACY_LOCATION_TIMEOUT = parseInt(
  process.env.REACT_APP_CONTACT_HIGH_ACCURACY_LOCATION_TIMEOUT as string,
);
/**
 * Milliseconds
 */
export const CONTACT_LOW_ACCURACY_LOCATION_TIMEOUT = parseInt(
  process.env.REACT_APP_CONTACT_LOW_ACCURACY_LOCATION_TIMEOUT as string,
);
/**
 * Milliseconds
 */
export const SEND_LOCATION_TIMEOUT = parseInt(
  process.env.REACT_APP_SEND_LOCATION_TIMEOUT as string,
);
