import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { Typography } from 'antd';

// Own
import ScannedImage from './scanned-image';
import useParams from './use-params';
import LocationMap from './location-map.styled';
import useDescription from './use-description';
import { useLoadScanTracking } from './use-load-scan-tracking';
import LoadingScreen from 'components/loading-screen';

const ScannedSafetag: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation('scanned-safetag');
  const { itemName, hour, img, address, scanTrackingUuid } = useParams();

  const { data, loading } = useLoadScanTracking(scanTrackingUuid);

  const description = useDescription({
    address: data.address || address,
    hour,
    itemName,
  });

  if (loading) return <LoadingScreen />;

  return (
    <div className={className}>
      <div className="container">
        <div className="container__content">
          <div className="container__content__img-container">
            {data.latitude && data.longitude ? (
              <LocationMap
                className="container__img"
                coordinates={{ latitude: data.latitude, longitude: data.longitude }}
                address={data.address || address}
                img={img}
              />
            ) : (
              <ScannedImage />
            )}
          </div>
          <div className="container__content__text-container">
            <h1>{t('content.title')}</h1>
            <Typography.Paragraph
              type="secondary"
              className="container__content__primary-paragraph"
            >
              <div dangerouslySetInnerHTML={description}></div>
            </Typography.Paragraph>
            <Typography.Paragraph
              type="secondary"
              className="container__content__secondary-paragraph"
            >
              {t('content.disclaimer')}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  className?: string;
}

export default ScannedSafetag;
