// Own
import { ContactMethod } from './types';
import useGoToWhatsapp from './use-go-to-whatsapp';
import useGoToCall from './use-go-to-call';
import useGoToDownload from './use-go-to-download';
import { UserItem } from 'core/contact-owner/type';

export default function useNavigationByContactMethod(userItem: UserItem) {
  const goToWhatsapp = useGoToWhatsapp(userItem!);
  const goToCall = useGoToCall(userItem);
  const goToDownload = useGoToDownload();

  return (contactMethod: ContactMethod) => {
    switch (contactMethod) {
      case ContactMethod.Whatsapp:
        return goToWhatsapp;
      case ContactMethod.Call:
        return goToCall;
      case ContactMethod.Download:
        return goToDownload;
      default:
        return () => {};
    }
  };
}
